<template>
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card body-->
    <div class="card-body">
      <!--begin::Notice-->
      <div
        class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6"
      >
        <!--begin::Icon-->
        <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
        <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <rect
              opacity="0.3"
              x="2"
              y="2"
              width="20"
              height="20"
              rx="10"
              fill="black"
            ></rect>
            <rect
              x="11"
              y="14"
              width="7"
              height="2"
              rx="1"
              transform="rotate(-90 11 14)"
              fill="black"
            ></rect>
            <rect
              x="11"
              y="17"
              width="2"
              height="2"
              rx="1"
              transform="rotate(-90 11 17)"
              fill="black"
            ></rect>
          </svg>
        </span>
        <!--end::Svg Icon-->
        <!--end::Icon-->
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-grow-1">
          <!--begin::Content-->
          <div class="fw-bold">
            <h4 class="text-gray-900 fw-bolder">We need your attention!</h4>
            <div class="fs-6 text-gray-700">
              Your payment was declined. To start using tools, please
              <a
                href="#"
                class="fw-bolder"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_new_card"
                >Add Payment Method</a
              >.
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Notice-->
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-7">
          <!--begin::Heading-->
          <h3 class="mb-2">Active until Dec 09, 2022</h3>
          <p class="fs-6 text-gray-600 fw-bold mb-6 mb-lg-15">
            We will send you a notification upon Subscription expiration
          </p>
          <!--end::Heading-->
          <!--begin::Info-->
          <div class="fs-5 mb-2">
            <span class="text-gray-800 fw-bolder me-1">$24.99</span>
            <span class="text-gray-600 fw-bold">Per Month</span>
          </div>
          <!--end::Info-->
          <!--begin::Notice-->
          <div class="fs-6 text-gray-600 fw-bold">
            Extended Pro Package. Up to 100 Agents &amp; 25 Projects
          </div>
          <!--end::Notice-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-5">
          <!--begin::Heading-->
          <div class="d-flex text-muted fw-bolder fs-5 mb-3">
            <span class="flex-grow-1 text-gray-800">Users</span>
            <span class="text-gray-800">86 of 100 Used</span>
          </div>
          <!--end::Heading-->
          <!--begin::Progress-->
          <div class="progress h-8px bg-light-primary mb-2">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              style="width: 86%"
              aria-valuenow="86"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <!--end::Progress-->
          <!--begin::Description-->
          <div class="fs-6 text-gray-600 fw-bold mb-10">
            14 Users remaining until your plan requires update
          </div>
          <!--end::Description-->
          <!--begin::Action-->
          <div class="d-flex justify-content-end pb-0 px-0">
            <a href="#" class="btn btn-light btn-active-light-primary me-2"
              >Cancel Subscription</a
            >
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_upgrade_plan"
            >
              Upgrade Plan
            </button>
          </div>
          <!--end::Action-->
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Card body-->
  </div>

  <!--begin::Pricing card-->
  <div class="card" id="kt_pricing">
    <!--begin::Card body-->
    <div class="card-body p-lg-17">
      <!--begin::Plans-->
      <div class="d-flex flex-column">
        <!--begin::Heading-->
        <div class="mb-13 text-center">
          <h1 class="fs-2hx fw-bolder mb-5">Choose Your Plan</h1>
          <div class="text-gray-400 fw-bold fs-5">
            If you need more info about our pricing, please check
            <a href="#" class="link-primary fw-bolder">Pricing Guidelines</a>.
          </div>
        </div>
        <!--end::Heading-->
        <!--begin::Nav group-->
        <div
          class="nav-group nav-group-outline mx-auto mb-15"
          data-kt-buttons="true"
        >
          <a
            href="#"
            class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active"
            data-kt-plan="month"
            >Monthly</a
          >
          <a
            href="#"
            class="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3"
            data-kt-plan="annual"
            >Annual</a
          >
        </div>
        <!--end::Nav group-->
        <!--begin::Row-->
        <div class="row g-10">
          <!--begin::Col-->
          <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
              <!--begin::Option-->
              <div
                class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10"
              >
                <!--begin::Heading-->
                <div class="mb-7 text-center">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-5 fw-boldest">Startup</h1>
                  <!--end::Title-->
                  <!--begin::Description-->
                  <div class="text-gray-400 fw-bold mb-5">
                    Optimal for 10+ team size <br />and new startup
                  </div>
                  <!--end::Description-->
                  <!--begin::Price-->
                  <div class="text-center">
                    <span class="mb-2 text-primary">$</span>
                    <span
                      class="fs-3x fw-bolder text-primary"
                      data-kt-plan-price-month="39"
                      data-kt-plan-price-annual="399"
                      >39</span
                    >
                    <span class="fs-7 fw-bold opacity-50"
                      >/ <span data-kt-element="period">Mon</span></span
                    >
                  </div>
                  <!--end::Price-->
                </div>
                <!--end::Heading-->
                <!--begin::Features-->
                <div class="w-100 mb-10">
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 10 Active Users</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 30 Project Integrations</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Analytics Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Finance Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Accounting Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Network Platform</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Unlimited Cloud Space</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Features-->
                <!--begin::Select-->
                <a href="#" class="btn btn-sm btn-primary">Select</a>
                <!--end::Select-->
              </div>
              <!--end::Option-->
            </div>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
              <!--begin::Option-->
              <div
                class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-20 px-10"
              >
                <!--begin::Heading-->
                <div class="mb-7 text-center">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-5 fw-boldest">Advanced</h1>
                  <!--end::Title-->
                  <!--begin::Description-->
                  <div class="text-gray-400 fw-bold mb-5">
                    Optimal for 100+ team siz <br />e and grown company
                  </div>
                  <!--end::Description-->
                  <!--begin::Price-->
                  <div class="text-center">
                    <span class="mb-2 text-primary">$</span>
                    <span
                      class="fs-3x fw-bolder text-primary"
                      data-kt-plan-price-month="339"
                      data-kt-plan-price-annual="3399"
                      >339</span
                    >
                    <span class="fs-7 fw-bold opacity-50"
                      >/ <span data-kt-element="period">Mon</span></span
                    >
                  </div>
                  <!--end::Price-->
                </div>
                <!--end::Heading-->
                <!--begin::Features-->
                <div class="w-100 mb-10">
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 10 Active Users</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 30 Project Integrations</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Analytics Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Finance Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Accounting Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Network Platform</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center">
                    <span class="fw-bold fs-6 text-gray-400 flex-grow-1"
                      >Unlimited Cloud Space</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen040.svg-->
                    <span class="svg-icon svg-icon-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <rect
                          x="7"
                          y="15.3137"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(-45 7 15.3137)"
                          fill="black"
                        />
                        <rect
                          x="8.41422"
                          y="7"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(45 8.41422 7)"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Features-->
                <!--begin::Select-->
                <a href="#" class="btn btn-sm btn-primary">Select</a>
                <!--end::Select-->
              </div>
              <!--end::Option-->
            </div>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-xl-4">
            <div class="d-flex h-100 align-items-center">
              <!--begin::Option-->
              <div
                class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10"
              >
                <!--begin::Heading-->
                <div class="mb-7 text-center">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-5 fw-boldest">Enterprise</h1>
                  <!--end::Title-->
                  <!--begin::Description-->
                  <div class="text-gray-400 fw-bold mb-5">
                    Optimal for 1000+ team <br />and enterpise
                  </div>
                  <!--end::Description-->
                  <!--begin::Price-->
                  <div class="text-center">
                    <span class="mb-2 text-primary">$</span>
                    <span
                      class="fs-3x fw-bolder text-primary"
                      data-kt-plan-price-month="999"
                      data-kt-plan-price-annual="9999"
                      >999</span
                    >
                    <span class="fs-7 fw-bold opacity-50"
                      >/ <span data-kt-element="period">Mon</span></span
                    >
                  </div>
                  <!--end::Price-->
                </div>
                <!--end::Heading-->
                <!--begin::Features-->
                <div class="w-100 mb-10">
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 10 Active Users</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Up to 30 Project Integrations</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Analytics Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Finance Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Accounting Module</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center mb-5">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Network Platform</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                  <!--begin::Item-->
                  <div class="d-flex align-items-center">
                    <span class="fw-bold fs-6 text-gray-800 flex-grow-1 pe-3"
                      >Unlimited Cloud Space</span
                    >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen043.svg-->
                    <span class="svg-icon svg-icon-1 svg-icon-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="10"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Features-->
                <!--begin::Select-->
                <a href="#" class="btn btn-sm btn-primary">Select</a>
                <!--end::Select-->
              </div>
              <!--end::Option-->
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Plans-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Pricing card-->
</template>
